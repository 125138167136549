@import "subcategories-settings";

#subcategories-spinner {
  height: $subcategories-spinner-height;
  text-align: center;
  color: #888888;
  padding-top: 50px;
}

#subcategories-container {
  display: none;
  padding-bottom: 12px;
  margin-bottom: 24px;
  transition: all .3s ease .15s;

  .subcategories-item {
    float: left;
    margin-bottom: 12px;

    .subcategories-link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .subcategories-image {
      margin-bottom: 12px;
    }
    .subcategories-name {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      word-break: break-all;
    }
  }

  &.subcategories-has-no-images {
    .subcategories-name {
      background-color: #d3dce0;
      //border: 1px solid darken(#efefef, 10%);
      padding: 10px;
      &:hover {
        background-color: darken(#d3dce0, 10%);
      }
    }
  }
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width($subcategories-total-items-medium, $subcategories-item-margin-right, $subcategories-item-selector);
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width($subcategories-total-items-small, $subcategories-item-margin-right, $subcategories-item-selector);
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width($subcategories-total-items-large, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width($subcategories-total-items-xlarge, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width($subcategories-total-items-xxlarge, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}