//
//  Note: The menu and menu-button are handled in _menu.scss
//

//
//  Small only
//
@media #{$small-only} {

  .ajax__tab_tab {
    padding: 4px 12px !important;
    font-size: 13px !important;
  }

  td.search-article-number {
    display: none;
  }

  .news-list-wrapper {
    .news-list-item-image,
    .news-list-item-text {
      display: none;
    }
    .news-list-item-date {
      clear: both;
    }
    .news-list-item-separator {
      clear: both;
    }
    h3 {
      float: left;
    }
    .news-list-item-link {
      float: right;
    }
  }

}
//
//  Small & Medium Only
//
@media #{$medium-down} {

  .siteMapGroup {
    .siteMapLeft, .siteMapRight {
      float: none;
    }
  }


  
}







//
//  Medium only
//
@media #{$medium-only} {

}

//
//  Large
//
@media #{$large-only} {

}

//
//  X-Large
//
@media #{$xlarge-only} {

}

//
//  XX-Large
//
@media #{$xxlarge-only} {


}




