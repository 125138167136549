
//
//  Speed Order
//
table.speed-order-form {
  width: 100% !important;
  max-width: 700px !important;
}

table.speed-order-wrapper {
  margin: 0 !important;
}

table.speed-order-form tr:first-child td {
  font-size: 12px !important;
}