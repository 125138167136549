html.page-responsive-orderconfirmed {
  .InfoTextWrapper{
    display: none !important;
  }
  .order-details-confirmed-summary-wrapper {
    margin-bottom: 12px;
  }

  .order-details-confirmed-box {
    margin-bottom: 22px;
  }

}