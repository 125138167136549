
/* NEWS PAGE AND NEWS ARCHIVE
----------------------------------------------------------------------------- */
.NewsDetailsWrapper, .NewsListArchiveWrapper {
  margin: 0;
}

.NewsDetailsDate, .NewsListArchiveItemDate {
  border-top: 1px solid #dfdfdf;
  padding: 10px 0;
}

.NewsDetailsTeaser {
  margin: 0 0 10px 0;
  @include js-description-text($js-description-font-size+0.2em);
  font-style: italic;
}

.NewsDetailsImage {
  margin: 10px 0 20px 0;
}

.NewsListArchiveItem {
  margin: 0 0 20px 0;
}

.NewsListArchiveItemImage {
  display: none;
}

.NewsArchiveButton {
  float: left;
}

.NewsDetailsText {
  @include js-description-text($js-description-font-size);
}
