#loadingOverlay {
  display: none !important;
}

/* -- Overlay ----------------------------------------------- */
.pi-overlay {
  color: #FFFFFF;
  height: 70px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  width: 70px;
  background-repeat: no-repeat;
  display: run-in;
  overflow: hidden;
}

.pio-top-left, .pio-top-right, .pio-bottom-left, .pio-bottom-right {
  position: absolute;
  pointer-events: none;
}

.pio-top-left {
  left: 0;
  top: 0;
}

.pio-top-right {
  right: 0;
  top: 0;
}

.pio-bottom-left {
  left: 0;
  bottom: 0;
}

.pio-bottom-right {
  right: 0;
  bottom: 0;
}

.pi-checkout {
  background-size: 100% auto;
  font-size: 78%;
  height: 55px;
  width: 35px;
}

.pi-overlay-search {
  background-size: 100% auto;
  font-size: 78%;
  height: 55px;
  width: 35px;
}

.pio-top-left {
  background-position: top left;
}

.pio-top-right {
  background-position: top right;
}

.pio-bottom-left {
  background-position: bottom left;
}

.pio-bottom-right {
  background-position: bottom right;
}

/* X */
.pi-sv-overlay4, .pi-da-overlay4, .pi-nb-overlay4, .pi-fi-overlay4, .pi-fr-overlay4, .pi-de-overlay4, .pi-it-overlay4, .pi-en-overlay4 {
  background-image: url("/images/overlay/overlay4.png");
}

.pi-sv-overlay5, .pi-da-overlay5, .pi-nb-overlay5, .pi-fi-overlay5, .pi-fr-overlay5, .pi-de-overlay5, .pi-it-overlay5, .pi-en-overlay5 {
  background-image: url("/images/overlay/overlay5.png");
}

/* en-GB */
.pi-en-overlay1 {
  background-image: url("/images/overlay/overlay1_en-GB.png");
}

.pi-en-overlay2 {
  background-image: url("/images/overlay/overlay2_en-GB.png");
}

.pi-en-overlay3 {
  background-image: url("/images/overlay/overlay3_en-GB.png");
}

.pi-en-overlay6 {
  background-image: url("/images/overlay/overlay6_en-GB.png");
}

.pi-en-overlay7 {
  background-image: url("/images/overlay/overlay7_en-GB.png");
}

.pi-en-overlay8 {
  background-image: url("/images/overlay/overlay8_en-GB.png");
}

.pi-en-overlay9 {
  background-image: url("/images/overlay/overlay9_en-GB.png");
}

.pi-en-overlay10 {
  background-image: url("/images/overlay/overlay10_en-GB.png");
}

/* sv */
.pi-sv-overlay1 {
  background-image: url("/images/overlay/overlay1_sv-SE.png");
}

.pi-sv-overlay2 {
  background-image: url("/images/overlay/overlay2_sv-SE.png");
}

.pi-sv-overlay3 {
  background-image: url("/images/overlay/overlay3_sv-SE.png");
}

.pi-sv-overlay6 {
  background-image: url("/images/overlay/overlay6_sv-SE.png");
}

.pi-sv-overlay7 {
  background-image: url("/images/overlay/overlay7_sv-SE.png");
}

.pi-sv-overlay8 {
  background-image: url("/images/overlay/overlay8_sv-SE.png");
}

.pi-sv-overlay9 {
  background-image: url("/images/overlay/overlay9_sv-SE.png");
}

.pi-sv-overlay10 {
  background-image: url("/images/overlay/overlay10_sv-SE.png");
}

/* da */
.pi-da-overlay1 {
  background-image: url("/images/overlay/overlay1_da-DK.png");
}

.pi-da-overlay2 {
  background-image: url("/images/overlay/overlay2_da-DK.png");
}

.pi-da-overlay3 {
  background-image: url("/images/overlay/overlay3_da-DK.png");
}

.pi-da-overlay6 {
  background-image: url("/images/overlay/overlay6_da-DK.png");
}

.pi-da-overlay7 {
  background-image: url("/images/overlay/overlay7_da-DK.png");
}

.pi-da-overlay8 {
  background-image: url("/images/overlay/overlay8_da-DK.png");
}

.pi-da-overlay9 {
  background-image: url("/images/overlay/overlay9_da-DK.png");
}

.pi-da-overlay10 {
  background-image: url("/images/overlay/overlay10_da-DK.png");
}

/* nb */
.pi-nb-overlay1 {
  background-image: url("/images/overlay/overlay1_nb-NO.png");
}

.pi-nb-overlay2 {
  background-image: url("/images/overlay/overlay2_nb-NO.png");
}

.pi-nb-overlay3 {
  background-image: url("/images/overlay/overlay3_nb-NO.png");
}

.pi-nb-overlay6 {
  background-image: url("/images/overlay/overlay6_nb-NO.png");
}

.pi-nb-overlay7 {
  background-image: url("/images/overlay/overlay7_nb-NO.png");
}

.pi-nb-overlay8 {
  background-image: url("/images/overlay/overlay8_nb-NO.png");
}

.pi-nb-overlay9 {
  background-image: url("/images/overlay/overlay9_nb-NO.png");
}

.pi-nb-overlay10 {
  background-image: url("/images/overlay/overlay10_nb-NO.png");
}

/* fi */
.pi-fi-overlay1 {
  background-image: url("/images/overlay/overlay1_fi-FI.png");
}

.pi-fi-overlay2 {
  background-image: url("/images/overlay/overlay2_fi-FI.png");
}

.pi-fi-overlay3 {
  background-image: url("/images/overlay/overlay3_fi-FI.png");
}

.pi-fi-overlay6 {
  background-image: url("/images/overlay/overlay6_fi-FI.png");
}

.pi-fi-overlay7 {
  background-image: url("/images/overlay/overlay7_fi-FI.png");
}

.pi-fi-overlay8 {
  background-image: url("/images/overlay/overlay8_fi-FI.png");
}

.pi-fi-overlay9 {
  background-image: url("/images/overlay/overlay9_fi-FI.png");
}

.pi-fi-overlay10 {
  background-image: url("/images/overlay/overlay10_fi-FI.png");
}

/* fr */
.pi-fr-overlay1 {
  background-image: url("/images/overlay/overlay1_fr-FR.png");
}

.pi-fr-overlay2 {
  background-image: url("/images/overlay/overlay2_fr-FR.png");
}

.pi-fr-overlay3 {
  background-image: url("/images/overlay/overlay3_fr-FR.png");
}

.pi-fr-overlay6 {
  background-image: url("/images/overlay/overlay6_fr-FR.png");
}

.pi-fr-overlay7 {
  background-image: url("/images/overlay/overlay7_fr-FR.png");
}

.pi-fr-overlay8 {
  background-image: url("/images/overlay/overlay8_fr-FR.png");
}

.pi-fr-overlay9 {
  background-image: url("/images/overlay/overlay9_fr-FR.png");
}

.pi-fr-overlay10 {
  background-image: url("/images/overlay/overlay10_fr-FR.png");
}

/* de */
.pi-de-overlay1 {
  background-image: url("/images/overlay/overlay1_de-DE.png");
}

.pi-de-overlay2 {
  background-image: url("/images/overlay/overlay2_de-DE.png");
}

.pi-de-overlay3 {
  background-image: url("/images/overlay/overlay3_de-DE.png");
}

.pi-de-overlay6 {
  background-image: url("/images/overlay/overlay6_de-DE.png");
}

.pi-de-overlay7 {
  background-image: url("/images/overlay/overlay7_de-DE.png");
}

.pi-de-overlay8 {
  background-image: url("/images/overlay/overlay8_de-DE.png");
}

.pi-de-overlay9 {
  background-image: url("/images/overlay/overlay9_de-DE.png");
}

.pi-de-overlay10 {
  background-image: url("/images/overlay/overlay10_de-DE.png");
}

/* it */
.pi-it-overlay1 {
  background-image: url("/images/overlay/overlay1_it-IT.png");
}

.pi-it-overlay2 {
  background-image: url("/images/overlay/overlay2_it-IT.png");
}

.pi-it-overlay3 {
  background-image: url("/images/overlay/overlay3_it-IT.png");
}

.pi-it-overlay6 {
  background-image: url("/images/overlay/overlay6_it-IT.png");
}

.pi-it-overlay7 {
  background-image: url("/images/overlay/overlay7_it-IT.png");
}

.pi-it-overlay8 {
  background-image: url("/images/overlay/overlay8_it-IT.png");
}

.pi-it-overlay9 {
  background-image: url("/images/overlay/overlay9_it-IT.png");
}

.pi-it-overlay10 {
  background-image: url("/images/overlay/overlay10_it-IT.png");
}