
html.page-checkout {

  body.one-column #main-area {
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    float: none;
  }

  #help1, #help2, #help3, #help4, #help5
  {
    width: 100% !important;
    //max-width: 360px !important;
  }

  #ctl00_main_fastcheckout_FastCart_hlContinueShopping {
    background-color: $js-button-background-color;
    color: $js-button-color;
  }

  #ctl00_main_fastcheckout_FastCart_hlContinueShopping:hover {
    background-color: darken($js-button-background-color,10%);
    text-decoration: none;
  }


}


.fastcartconfirmationpage {
  width: 100%; // 918px
}

.page-orderdetailsconfirmed #path-nav {
  display: none !important;
}

.PlacedOrderDetailsHeaderCell1,
.PlacedOrderDetailsHeaderCell2 {
  display: none;
}

.fastcartconfirmationpage td.CartContentsHeader {
  font-size: 0.9em;
}

.fastcartconfirmationpage td.CartArticleSums {
  font-size: 0.9em;
}

.fastcartconfirmationpage {
  background-color: transparent;
  background-image: none;
  width: 100%;
  margin: 12px 0;
  padding: 0;
  border: none;
  float: left;
}

.paymenterrormessagetitle {
  font-family: $font-family-header;
}

