#store-container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding: 4px 0;
  background: black;
  .store-inner{
    max-width: 1200px;
    margin: 0  auto;
    display: flex;
    justify-content: flex-end;
    #store-select-title{
      p{
        margin: 0 11px 0 0;
        line-height: 32px;
        color: white;
      }
      @media #{$small-only} {
        display: none;
      }
    }
    #store-selects{
      display: flex;
      select{
        height: 32px;
        margin: 0 5px;
        border: 1px solid white;
        padding: 0 12px;
      }
    }
  }
}