html.page-klarnainstorewaitingpage{
  #left-area {
    display: none;
  }
}

html.page-responsive-checkout,
html.page-responsive-orderconfirmed {
  #left-area {
    display: none;
  }
  // Checkouts
  h1 {
    max-width: $js-checkout-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  // Checkout width
  .checkout-main-wrapper {
    max-width: $js-checkout-max-width;
  }

  // Numbered bullets
  h2.component-header {
    span.component-header-number {
      background-color: $js-checkout-header-number-color;
    }
  }

  // Big checkout button
  .form-button.form-button-checkout {
    background-color: $js-checkout-checkout-background-color;
    &:focus {
      background-color: darken($js-checkout-checkout-background-color, $js-checkout-form-button-factor);
    }
    &:hover {
      background-color: darken($js-checkout-checkout-background-color, $js-checkout-form-button-factor);
    }
  }

  // Big checkout button - No items in cart
  .empty-cart-wrapper .continue-shopping-button.form-button {
    &:focus {
      outline: none !important;
    }
  }

  // Country selector - Active item
  .country-selector-item-link.country-selector-item-link-selected {
    background-color: $js-checkout-country-selector-item-active-background-color;
    color: $js-checkout-country-selector-item-active-color;
  }

  // Payment selector - Active item
  .payment-selector-wrapper {
    .payment-selector-item.payment-selector-item-selected, .payment-selector-item-link.payment-selector-item-link-selected {
      background-color: $js-checkout-payment-selector-item-active-background-color;
      color: $js-checkout-payment-selector-item-active-color;
      .payment-selector-item-options {
        background-color: lighten($js-checkout-payment-selector-item-active-background-color,5%);
        //border-top: 1px solid darken($js-checkout-payment-selector-item-active-background-color, 10%);
      }
    }
  }

  // Freight selector - Active item
  .freight-selector-wrapper {
    .freight-selector-item {
      .freight-selector-item-link.aspNetDisabled {
        background-color: $js-checkout-freight-selector-item-active-background-color;
        color: $js-checkout-freight-selector-item-active-color;
      }
    }
  }

  //
  //  Voucher box
  //
  .discount-wrapper .discount-header {
    text-decoration: none;
    color: $primary-color;
    border-bottom: 1px dotted $primary-color;
    &:hover {
      color: darken($primary-color, 15%);
    }
  }
  .discount-wrapper .discount-form {
    display: block !important;
  }
  .discount-wrapper .discount-button  {
    background-color: $primary-color !important;
  }
  .discount-wrapper .discount-button:hover  {
    background-color: darken($primary-color, 15%) !important;
  }
  //
  //  Free Freight Message
  //
  /*
  .responsive-free-freight.responsive-free-freight-placement-cartsummary,
  .responsive-free-freight.responsive-free-freight-placement-orderconfirmation {
    color: $js-checkout-freefreight-background-color;
    background-color: lighten($js-checkout-freefreight-background-color, 45%);
    border: 1px solid lighten($js-checkout-freefreight-background-color, 35%);

    &.responsive-free-freight-limit-reached {
      background-color: lighten($js-checkout-freefreight-reached-background-color, 50%);
      border: 1px solid lighten($js-checkout-freefreight-reached-background-color, 15%);
      color: $js-body-font-color;
    }
  }
  */

  //
  //  Small only
  //
  @media #{$small-only} {
    // Checkout
    h1 {
      // Log In & Change Customer Type
      .change-customertype-button,
      a.cart-header-login-button {
        display: block;
        float: none;
      }
      .change-customertype-button {
        margin: 12px 0 3px;
        border-top: 1px solid #aaa;
        padding: 9px 6px 3px;
      }
      a.cart-header-login-button {
        margin: 3px 0 12px;
        border-bottom: 1px solid #aaa;
        padding: 3px 6px 9px;
      }
    }

    // Cart item image needs a max height value for portrait image format
    .cart-details-item .cart-details-image img {
      max-height: 100px;
    }
  }

  //
  //  Medium up
  //
  @media #{$medium-up} {
    // Checkout
    h1 {
      // Log In & Change Customer Type
      .change-customertype-button,
      a.cart-header-login-button {
        border-bottom: 1px dotted $primary-color;
      }
      .change-customertype-button {
        margin-left: 8px;
        padding-left: 0;
      }
    }
  }
}