.page-top {
  background: $primary-color;
  color: #fff;
  padding: 13px 20px;
  font-weight: bold;
  font-family: $font-family-sans-serif-bold;
  font-size: 14px;
  a {
    color: #fff;
  }
  .page-top-left {
    float: left;
  }
  .page-top-right {
    float: right;
  }
  &:after {
    clear: both;
    content : '';
    display: block;
  }
  @media #{$small-only} {
    padding: 3px 20px;
    font-size: 12px;
  }
}

.bottom-logos {
  text-align: center;
  margin-bottom: 30px;
}

.bottom-logos img {
  max-width: 120px;
}

.simple-product-list-item-read-more {
  display: none;
}
.FreeFreight_Cart {
  display: none;
}
.footer-info-box {
  display: none;
}
h1 {
  color: $primary-color;
}
.page-top-right{
  .login-text, .mypages-text {
    margin-right: 10px;
  }
  .login-text:before {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    content: "\f023";
    float: left;
    display: block;
    margin-right: 5px;
  }
}
.footer-box a {
  display: block;
}
@media #{$small-only} {
  .footer-box {
    text-align: center;
  }
}

.call-us:before {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  content: "\f095";
  float: left;
  display: block;
  margin-right: 5px;
}
.Gilla {
  margin-top: 10px;
}

.category-header-wrapper .category-header-subtitle ul {
  list-style: disc;
  padding-left: 25px;
  margin-bottom: 10px;
  li {
    display: list-item;
    float: none;
    margin-bottom: 0;
    width: auto;
  }
}

.slick-dots {
  bottom:-25px;
}