//
//    Culture & Currency selector
//
.culture-selector-wrapper {
  margin-bottom: 12px;
}
.culture-selector-wrapper, .currency-selector-wrapper {
  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: $js-selector-background-color;
    color: $js-selector-color;
    @include border-radius($js-selector-border-radius);
    padding: 4px 10px;
    border: none;
    margin-bottom: 8px;
    min-width: 120px;
    -webkit-user-select: none;
    outline: none;
    &::-ms-expand {
      display: none;
    }
  }
  &.culture-selector-dropdown {
    &:after {
      content:"\f107";
      font-family: "FontAwesome";
      color: white;
      padding: 6px;
      position: relative;
      left: -30px;
      top: 0;
      background: transparent;
      text-align: center;
      pointer-events: none;
    }
  }
}
.currency-selector-dropdown {
  &:after {
    content:"\f107";
    font-family: "FontAwesome";
    color: white;
    padding: 6px;
    position: relative;
    left: -30px;
    top: 0;
    background: transparent;
    text-align: center;
    pointer-events: none;
  }
}
body.browser-firefox {
  .culture-selector-wrapper, .currency-selector-wrapper  {
    select {
      -moz-appearance: none;
      -moz-user-select: none;
      color: white;
      color: rgba(255,255,255,0);
      text-shadow: 0 0 0 white;
    }
  }
}
body.browser-internet-explorer {
  .culture-selector-wrapper, .currency-selector-wrapper  {
    &:after {
      display: inline-block;
    }
  }
}

//
//  VAT selector
//
.vat-selector-outer-wrapper {
  .vat-selector-wrapper {
    float: none;
    font-size: $js-body-font-size;
    .vat-selector-label {
      span {
        font-size: $js-body-font-size;
      }
    }
  }
}


//
//  Newsletter Box
//
.newsletter-box-wrapper {
  display: none;
}

.newsletter-box-header {
  background: none;
  border: 0;
  padding: 0;

  span {
    // Make this behave like a h2
    font-size: $h3-font-size;
    text-transform: $font-transform-header;
    color: $header-font-color;
    font-family: $header-font-family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    line-height: $header-line-height;
    margin-bottom: $header-bottom-margin;
    margin-top: $header-top-margin;
    text-rendering: $header-text-rendering;
  }
}

// Add the envelope icon after newsletter header
.newsletter-box-header span:after {
  font-family: FontAwesome;
  content: "\f003";
  padding-left: 6px;
}

.newsletter-box-body {
  background: none;
  border: 0;
  padding: 0;
}

.newsletter-box-body:hover {
  background: none;
}

.newsletter-box-text {
  display: none;
}

.newsletter-box-radio-subscribe {
  float: left;
  margin: 0 10px 10px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-radio-unsubscribe {
  float: left;
  margin: 0 0 10px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-input {
  float: left;
  clear: both;
  width: 100%;
  max-width: 180px;
  margin: 0 6px 0 0;
}

.newsletter-box-input input {
  border: 1px solid #cdcdcd;
  color: $js-footer-color;
  width: 100%;
  max-width: 180px;
  height: 28px;
  line-height: 28px;
  background-color: $js-footer-background-color;
  padding: 0 5px 0 10px;
  font-size: 0.9em;
}

.newsletter-box-button {
  float: left;
  a {
    color: $js-button-color !important;
  }
}

.newsletter-box-footer {
  background: none;
}


//
//    Footer
//
#footer {
  clear: both;
  position: relative;
  width: 100%;
  //background-color: $js-footer-background-color;
  //border-top: 1px solid $js-footer-border-top-color;
  font-weight: 700;
  color: #fff;
  background-color: #1976d2;
  padding-top: 22px;

  a {
    //color: $js-footer-color;
    color : #fff;
    font-weight: 700;
  }
  a:hover {
    //color: darken($js-footer-color,10%);
    text-decoration: underline;
  }
}

.footer-lower {
  overflow: hidden;
  width: 100%;
  max-width: $js-max-page-width;
  margin: 0 auto;
  padding: 0 $js-page-padding-right 60px $js-page-padding-left;
  //background: $js-footer-background-color;
}

#jetshop-branding {
  //background-color: black;
  padding: 16px;
  clear: both;
  background-color: #163aa9;
}
.infoTextLogo {
  clear: both;
  width: 143px;
  margin: 00px auto;
}
.footer-logos {
  overflow: hidden;
  width: 100%;
  max-width: $js-max-page-width;
  margin: 0 auto;
}
.footer-box {
  padding-right: 10px;
  padding-bottom: 12px;
  float: left;
  width: 25%;
  h2, .newsletter-box-header span {
    font-size: 16px;
    margin-bottom: 16px;
    color: $primary-color;
  }
  p {
    margin-bottom: 2px;
  }
  h2 {
    i {
      padding-left: 8px;
    }
  }
}
@include calculate-item-width(1, 3, "#footer.footer-box-count-1 .footer-box");
@include calculate-item-width(2, 3, "#footer.footer-box-count-2 .footer-box");
@include calculate-item-width(3, 3, "#footer.footer-box-count-3 .footer-box");
@include calculate-item-width(4, 3, "#footer.footer-box-count-4 .footer-box");
@include calculate-item-width(5, 3, "#footer.footer-box-count-5 .footer-box");
@include calculate-item-width(6, 3, "#footer.footer-box-count-6 .footer-box");




//
//  Small
//
@media #{$small-only} {
  #footer .footer-box {
    padding-bottom: 22px;
  }
  @include calculate-item-width(1, 0, "#footer.footer-box-count-3 .footer-box");
  @include calculate-item-width(1, 0, "#footer.footer-box-count-4 .footer-box");
  @include calculate-item-width(1, 0, "#footer.footer-box-count-5 .footer-box");
  @include calculate-item-width(1, 0, "#footer.footer-box-count-6 .footer-box");
}

//
//  Medium
//
@media #{$medium-only} {
  #footer .footer-box {
    padding-bottom: 22px;
  }
  @include calculate-item-width(2, 2, "#footer.footer-box-count-3 .footer-box");
  @include calculate-item-width(2, 2, "#footer.footer-box-count-4 .footer-box");
  @include calculate-item-width(2, 2, "#footer.footer-box-count-5 .footer-box");
  @include calculate-item-width(2, 2, "#footer.footer-box-count-6 .footer-box");
}