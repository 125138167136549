// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

//
//  This is a modified version of foundation/_type.scss,
//  adjusted to fit the framework. All vars are set in _base_settings.scss
//

@import '../foundation/global';

$include-html-type-classes: $include-html-classes;

//
// Typography Placeholders
//

// These will throw a deprecation warning if used within a media query.
@mixin lead {
  font-size: $paragraph-font-size + rem-calc(3.5);
  line-height: 1.6;
}

@mixin subheader {
  line-height: $subheader-line-height;
  color: $subheader-font-color;
  font-weight: $subheader-font-weight;
  margin-top: $subheader-top-margin;
  margin-bottom: $subheader-bottom-margin;
}
@include exports("type") {
  @if $include-html-type-classes {

    /* Default Link Styles */
    a {
      color: $anchor-font-color;
      line-height: inherit;
      text-decoration: $anchor-text-decoration;

      &:hover,
      &:focus {
        color: $anchor-font-color-hover;
        @if $anchor-text-decoration-hover != $anchor-text-decoration {
        	text-decoration: $anchor-text-decoration-hover;
        }
      }

      img { border:none; }
    }

    /* Default paragraph styles */
    p {
      font-family: $body-font-family;
      font-size: $js-body-font-size;
      font-weight: $paragraph-font-weight;
      line-height: $paragraph-line-height;
      margin-bottom: $paragraph-margin-bottom;
      text-rendering: $paragraph-text-rendering;

      &.lead { @include lead; }

      & aside {
        font-size: $paragraph-aside-font-size;
        font-style: $paragraph-aside-font-style;
        line-height: $paragraph-aside-line-height;
      }
    }

    /* Default header styles */
    h1, h2, h3, h4, h5, h6 {
      color: $header-font-color;
      font-family: $header-font-family;
      font-style: $header-font-style;
      font-weight: $header-font-weight;
      line-height: $header-line-height;
      margin-bottom: $header-bottom-margin;
      margin-top: $header-top-margin;
      text-rendering: $header-text-rendering;
      text-transform: $font-transform-header;

      small {
        color: $small-font-color;
        font-size: $small-font-size;
        line-height: 0;
      }
    }

    h1 { font-size: $h1-font-size - $h1-font-reduction; }
    h2 { font-size: $h2-font-size - $h2-font-reduction; }
    h3 { font-size: $h3-font-size - $h3-font-reduction; }
    h4 { font-size: $h4-font-size - $h4-font-reduction; }
    h5 { font-size: $h5-font-size - $h5-font-reduction; }
    h6 { font-size: $h6-font-size - $h6-font-reduction; }

    .subheader { @include subheader; }

    hr {
      border: $hr-border-style $hr-border-color;
      border-width: $hr-border-width 0 0;
      clear: both;
      height: 0;
      margin: $hr-margin 0 ($hr-margin - rem-calc($hr-border-width));
    }

    /* Helpful Typography Defaults */
    em,
    i {
      font-style: italic;
      line-height: inherit;
    }

    strong,
    b {
      font-weight: $font-weight-bold;
      line-height: inherit;
    }

    small {
      font-size: $small-font-size;
      line-height: inherit;
    }

    code {
      background-color: $code-background-color;
      border-color: $code-border-color;
      border-style: $code-border-style;
      border-width: $code-border-size;
      color: $code-color;
      font-family: $code-font-family;
      font-weight: $code-font-weight;
      padding: $code-padding;
    }

    /* Lists */
    ul {
      list-style-type: none;
    }

    ul,
    ol,
    dl {
      font-family: $list-font-family;
      font-size: $list-font-size;
      line-height: $list-line-height;
      list-style-position: $list-style-position;
      margin-bottom: $list-margin-bottom;
      padding: 0;
    }

    ul {
      margin: 0;
      //margin-#{$default-float}: $list-side-margin;
      &.no-bullet {
        //margin-#{$default-float}: $list-side-margin-no-bullet;
        margin: 0;
        li {
          ul,
          ol {
            margin-#{$default-float}: $list-nested-margin;
            margin-bottom: 0;
            list-style: none;
          }
        }
      }
    }
    /* Unordered Lists */
    ul {
      li {
        ul,
        ol {
          margin-#{$default-float}: $list-nested-margin;
          margin-bottom: 0;
        }
      }
      &.square,
      &.circle,
      &.disc {
        li ul { list-style: inherit; }
      }

      &.square { list-style-type: square; margin-#{$default-float}: $list-side-margin;}
      &.circle { list-style-type: circle; margin-#{$default-float}: $list-side-margin;}
      &.disc { list-style-type: disc; margin-#{$default-float}: $list-side-margin;}
      &.no-bullet { list-style: none; }
    }

    /* Ordered Lists */
    ol {
      margin-#{$default-float}: $list-ordered-side-margin;
      li {
        ul,
        ol {
          margin-#{$default-float}: $list-nested-margin;
          margin-bottom: 0;
        }
      }
    }

    /* Definition Lists */
    dl {
      dt {
        margin-bottom: $definition-list-header-margin-bottom;
        font-weight: $definition-list-header-weight;
      }
      dd { margin-bottom: $definition-list-margin-bottom; }
    }

    /* Abbreviations */
    abbr,
    acronym {
      text-transform: uppercase;
      font-size: 90%;
      color: $body-font-color;
      cursor: $cursor-help-value;
    }
    abbr {
      text-transform: none;
      &[title] {
        border-bottom: $acronym-underline;
      }
    }

    /* Blockquotes */
    blockquote {
      margin: 0 0 $paragraph-margin-bottom;
      padding: $blockquote-padding;
      border-#{$default-float}: $blockquote-border;

      cite {
        display: block;
        font-size: $blockquote-cite-font-size;
        color: $blockquote-cite-font-color;
        &:before {
          content: "\2014 \0020";
        }

        a,
        a:visited {
          color: $blockquote-cite-link-color;
        }
      }
    }
    blockquote,
    blockquote p {
      line-height: $paragraph-line-height;
      color: $blockquote-font-color;
    }

    /* Microformats */
    .vcard {
      display: inline-block;
      margin: $microformat-margin;
      border: $microformat-border-width $microformat-border-style $microformat-border-color;
      padding: $microformat-padding;

      li {
        margin: 0;
        display: block;
      }
      .fn {
        font-weight: $microformat-fullname-font-weight;
        font-size: $microformat-fullname-font-size;
      }
    }

    .vevent {
      .summary { font-weight: $microformat-summary-font-weight; }

      abbr {
        cursor: $cursor-default-value;
        text-decoration: $microformat-abbr-font-decoration;
        font-weight: $microformat-abbr-font-weight;
        border: none;
        padding: $microformat-abbr-padding;
      }
    }


    @media #{$medium-up} {
      h1, h2, h3, h4, h5, h6 { line-height: $header-line-height; }
      h1 { font-size: $h1-font-size; }
      h2 { font-size: $h2-font-size; }
      h3 { font-size: $h3-font-size; }
      h4 { font-size: $h4-font-size; }
      h5 { font-size: $h5-font-size; }
      h6 { font-size: $h6-font-size; }
    }
  }
}
